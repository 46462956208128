import { Injectable } from "@angular/core";
import { Notice } from "../admin/notice/notice.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoginService } from "./login.service";
import { Observable } from "rxjs";
import {apiUrl} from './api'
import {tap,filter, map} from 'rxjs/operators'

@Injectable()
export class NoticeService{
    notice:Notice
    constructor(private http:HttpClient, private loginService:LoginService){
        
    }
    
        listNotice:Notice[];

    cadastra(notice:Notice):Observable<any>{
        const headers = new HttpHeaders().set('Authorization',`Bearer ${this.loginService.user.acessToken}`).set("Content-Type",'application/json').set('Access-Control-Request-Headers','*');
    
        return this.http.post(`${apiUrl}/notice`,JSON.stringify({
            title:notice.title,
            description:notice.description,
            photo:notice.photo,
            priceBefore:notice.priceBefore,
            priceAfter:notice.priceAfter,
            tag:notice.tag,
            type:notice.type,
            spotlight:notice.spotlight
        }),{headers:headers})
    }

    pegaNoticia():Observable<any>{
        return this.http.get(`${apiUrl}/notice`);
    }

    excluiNoticia(_id:string):Observable<any>{
        const headers = new HttpHeaders().set('Authorization',`Bearer ${this.loginService.user.acessToken}`).set("Content-Type",'application/json').set('Access-Control-Request-Headers','*');
    
        return this.http.delete(`${apiUrl}/notice/${_id}`,{headers:headers})        
    }

    pegaNoticiaType(type:string):Observable<any>{
        return this.http.get(`${apiUrl}/notice/${type}`)
    }

    pegaNoticiaDestaque():Observable<any>{
        return this.http.get(`${apiUrl}/notice/destaque`)
    }
}