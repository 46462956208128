import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProductsRoutingComponent } from "./products-routing.component";
import { ProductsComponent } from "../products.component";

export const ProductsRoutes:Routes=[
    {
        path:'products/:type',
        component:ProductsRoutingComponent,
        children:[
            {
                path:'',
                component:ProductsComponent
            }
        ]
    }
]


@NgModule({
    imports:[
        RouterModule.forChild(ProductsRoutes)
    ],
    exports:[
        RouterModule
    ]
})
export class ProductsRoutingModule{}