import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide = true
  credenciais=false
  formValido:boolean=false
  email = new FormControl('', [Validators.required,Validators.email,]);
  password = new FormControl('',[Validators.required]);

  constructor(private loginService:LoginService, private router:Router) { }

  ngOnInit() {
    if(this.loginService.estaLogado()){
      this.router.navigate(['/home'])
    }
  }

  verificaForm():void{
    if(this.email.valid && this.password.valid){
      this.formValido=true
    }else{
      this.formValido=false
    }
  }

  login():void{
    this.loginService.login(this.email.value,this.password.value).subscribe(user=>{
      this.router.navigate(['/home'])
    }, response=>{
      this.credenciais=true
    }
    )
  }


}
