import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import{ MatFormFieldModule } from '@angular/material/form-field';
import{ MatInputModule } from '@angular/material/input';
import { MatRippleModule, MatNativeDateModule } from "@angular/material/core";
import{ MatSelectModule } from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import { MatDialogModule } from "@angular/material/dialog";
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule, MatCheckbox} from '@angular/material/checkbox';


const modules = [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    FlexLayoutModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    MatCardModule,
    MatCheckboxModule
];

@NgModule({
    imports:[...modules],
    exports:[...modules],
    declarations:[]
})
export class MaterialModule{}