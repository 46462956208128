import{Routes,RouterModule} from '@angular/router'
import { NgModule } from '@angular/core';

export const ROUTES:Routes=[
    {
        path:'',
        redirectTo:'/home',
        pathMatch:'full'
    },
    {
        path:'**',
        redirectTo:'/home',
        pathMatch:'full'
    }
]

@NgModule({
    imports:[RouterModule.forRoot(ROUTES)],
    exports:[
        RouterModule
    ]
})
export class AppRoutingModule{}