import { NgModule } from "@angular/core";
import { HomeRoutingModule } from "../routing/home-routing.module";
import { HomeComponent } from "../home.component";
import { HomeRoutingComponent } from "../routing/home-routing.component";
import { SlideDestaqueComponent } from "../slide-destaque/slide-destaque.component";
import { SlideshowModule } from 'ng-simple-slideshow'
import { SlideService } from "src/app/services/slide.service";
import { CardsTagsComponent } from "../cards-tags/cards-tags.component";
import { MaterialModule } from "src/app/shared/modules/material.module";
import { CarouselModule } from "angular2-carousel";
import { NoticeProductsComponent } from "../notice-products/notice-products.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { MerchanComponent } from "../merchan/merchan.component";
import { MerchanService } from "src/app/services/merchan.service";
import { PipesModule } from "src/app/shared/modules/pipes.module";
import { ComponentsSharedModule } from "src/app/shared/modules/componentsShared.module";
import { NgxLoadingModule } from 'ngx-loading';
@NgModule({
    imports: [
        HomeRoutingModule,

        SlideshowModule,
        ComponentsSharedModule,
        MaterialModule,
        CarouselModule,

        BrowserModule,
        PipesModule,
        CommonModule,
        NgxLoadingModule.forRoot({}),


    ],
    declarations: [
        HomeComponent,
        HomeRoutingComponent,
        CardsTagsComponent,
        NoticeProductsComponent,
        MerchanComponent
    ],
    exports: [
        HomeComponent
    ],
    providers: [
        SlideService, MerchanService
    ]
})
export class HomeModule { }