import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material.module';
import { HeaderComponent } from './header/header.component';
import { LoginModule } from './admin/login/module/login.module';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/module/home.module';
import { AddNoticeModule } from './admin/add-notice/module/add-notice.module';
import { CarouselModule } from 'angular2-carousel';
import { ProductsModule } from './products/module/products.module';
import { FormsModule } from '@angular/forms';
import { RealPipeTransform } from './shared/pipes/real.pipe';
import {ModalGalleryModule} from '@ks89/angular-modal-gallery';
import 'mousetrap';
import 'hammerjs';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { ContactModule } from './contact/module/contact.module';
import {NgxLoadingModule} from 'ngx-loading';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ModalGalleryModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    LoginModule,
    HomeModule,
    ProductsModule,
    AddNoticeModule,
    ContactModule,
    AppRoutingModule,
    MaterialModule,
    
    NgxLoadingModule.forRoot({}),

    CarouselModule  
   
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
