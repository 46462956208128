import { Routes, RouterModule } from "@angular/router";
import { LoginRoutingComponent } from "./login-routing.component";
import { LoginComponent } from "../login.component";
import { NgModule } from "@angular/core";

export const LoginRoutes:Routes=[
    {
        path:'area-restrita',
        component:LoginRoutingComponent,
        children:[
            {
                path:'',
                component:LoginComponent
            }
        ]
    }
]

@NgModule({
    imports:[
        RouterModule.forChild(LoginRoutes)
    ],
    exports:[
        RouterModule
    ]
})
export class LoginRoutingModule{}