import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'angular2-carousel';
import { NoticeService } from '../../services/notice.service';
import { Notice } from '../../admin/notice/notice.model';

import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'

@Component({
  selector: 'app-notice-products',
  templateUrl: './notice-products.component.html',
  styleUrls: ['./notice-products.component.css'],
  animations: [
    trigger('noticeTransition', [
      state('ready', style({ opacity: 1 })),
      transition('void=>ready', [
        style({ opacity: 0, transform: 'translate(-30px,-10px)' }),
        animate('800ms 0s ease-in-out')
      ])
    ])
  ]
})
export class NoticeProductsComponent implements OnInit {
  noticeTransition = 'ready';
  listaProdutos: Notice[];
  public loading = true;
  numberSlides:number=2;
  constructor(private noticeService: NoticeService) { }

  

  @ViewChild('topCarousel') topCarousel: CarouselComponent;
  ngOnInit() {
    if (window.matchMedia("(max-width: 1000px)").matches) {
      this.numberSlides=-1
    } else {
      
      /* The viewport is less than 400 pixels wide */
    }
    this.pegaProdutos()
  }

  pegaProdutos(): void {
    if(this.noticeService.listNotice==undefined){
      this.noticeService.pegaNoticiaDestaque().subscribe(notices => {
        this.noticeService.listNotice=notices;
        this.listaProdutos = notices;
        this.loading=false; 
        
      });
    }else{
      this.listaProdutos=this.noticeService.listNotice;
      this.loading=false; 
    }
     
 
    
    
  }


  reinit(): void {
    this.topCarousel.slideTo(0);
  }

}


