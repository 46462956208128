import { Injectable } from "@angular/core";
import { User } from "../admin/user/user.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {apiUrl} from './api'
import {tap,filter, map} from 'rxjs/operators'
import { Router } from "@angular/router";


@Injectable()
export class LoginService{
    user:User
    
    constructor(private http:HttpClient,private router:Router){}
    
    estaLogado():boolean{
        return this.user!==undefined
    }

    logout():void{
        this.user=undefined
        this.router.navigate(['/home'])
    }

    login(email:string, password:string):Observable<any>{
        const headers = new HttpHeaders().set('Content-Type','application/json').set('Access-Control-Request-Headers','*');
        return this.http.post<User>(`${apiUrl}/users/authenticate`,JSON.stringify({
            email,
            password
        }),{headers}).pipe(
            tap(user=>{
                this.user=user
            })
            )
    }

    
}