import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/shared/modules/material.module";
import { AddNoticeRoutingModule } from "../routing/add-noticeRouting.module";
import { AddNoticeComponent } from "../add-notice.component";
import { AddNoticeRoutingComponent } from "../routing/add-noticeRouting.component";
import { LoginService } from "src/app/services/login.service";
import { NoticeService } from "src/app/services/notice.service";

@NgModule({
    imports:[
        CommonModule,
        FormsModule,    
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        MaterialModule,
        AddNoticeRoutingModule
    ],
    declarations:[
        AddNoticeComponent,
        AddNoticeRoutingComponent
    ],
    exports:[
        AddNoticeComponent
    ],
    providers:[LoginService,NoticeService]
})
export class AddNoticeModule{}