import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { NoticeService } from 'src/app/services/notice.service';
import { Notice } from '../notice/notice.model';
import { SlideService } from 'src/app/services/slide.service';
import { Slide } from 'src/app/home/slide-destaque/model/slide.model';
import { MerchanService } from 'src/app/services/merchan.service';
import { Merchan } from '../merchan/merchan.model';

@Component({
  selector: 'app-add-notice',
  templateUrl: './add-notice.component.html',
  styleUrls: ['./add-notice.component.css']
})
export class AddNoticeComponent implements OnInit {
  constructor(private merchanService:MerchanService, private loginService:LoginService,private router:Router,private noticeService:NoticeService,private slideService:SlideService) { }

  estaLogado():Boolean{
    return this.loginService.estaLogado();
  }

  ngOnInit() {
    if(!this.estaLogado()){
      this.router.navigate(['/home'])
    }else{
      this.pegaSlide()
      this.pegaAnuncios()  
      this.pegaMerchan()
    }
    
  }

  
  displayedColumns: string[] = ['url','excluir'];

  //ABAIXO COMEÇAM CONFIGURAÇÕES DE NOTICIAS PROMOÇÃO E PRODUTO
  photo:String
  photoName:String =""
  notice:Notice={}
  listaNotices:Notice[]
  type:String
  tags:number
  spotlight:boolean=false
  name=new FormControl('',[Validators.required,Validators.minLength(3),Validators.maxLength(20)])
  description = new FormControl('',[Validators.required,Validators.minLength(20),Validators.maxLength(250)])
  priceBefore=new FormControl('',[Validators.required,Validators.pattern('^[0-9]{1,4}(\\,[0-9]{1,2})')])
  priceAfter=new FormControl('',[Validators.required,Validators.pattern('^[0-9]{1,4}(\\,[0-9]{1,2})')])

  autorizaCadastro():void{
    this.notice.type=this.type
    this.notice.title=this.name.value
    this.notice.description=this.description.value
    this.notice.priceBefore=this.priceBefore.value
    this.notice.priceAfter=this.priceAfter.value
    this.notice.tag=this.tags.toString().replace(' ','_').replace(' ','_')
    this.notice.priceAfter=this.notice.priceAfter.replace(',','.')
    this.notice.priceBefore=this.notice.priceBefore.replace(',','.')
    this.notice.photo=this.photo,
    this.notice.spotlight=this.spotlight
    this.noticeService.cadastra(this.notice).subscribe(notice=>{
      alert('Noticia adicionada com sucesso')
      this.router.navigate(['/home'])
    })
  }

  cadastrar():void{
    if(this.name.valid && this.description.valid && this.tags!=undefined && this.photoName!=""){
      if(this.type=='Promocao' && this.priceAfter.valid && this.priceAfter.valid){
        this.autorizaCadastro()
      } else if(this.type=='Produto'){
        this.autorizaCadastro()
      }else{
        alert('Favor preencher os campos obrigatórios')
      }
    }else{
      alert('Favor preencher os campos obrigatórios')
    }
  }

  pegaAnuncios():void{
    this.noticeService.pegaNoticia().subscribe(notices=>{
      this.listaNotices=notices
    })
  }

  excluiAnuncio(_id:string):void{
    if(confirm("Deseja realmente excluir este anuncio?")){
      this.noticeService.excluiNoticia(_id).subscribe(notice=>{
        this.pegaAnuncios()
      })
    } 
  }

  getUser():String{
    return this.loginService.user.name
  } 

  onFileChanged(event) {
    let arquivo:File =event.target.files[0]
    var myReader:FileReader = new FileReader()
    myReader.onloadend = (e) => {
      this.photo = myReader.result.toString()
      this.photoName=arquivo.name
    }
    myReader.readAsDataURL(arquivo);
  }

  //FIM DA IMPLEMENTAÇÃO DE PROMOÇÃO/PRODUTO E INICIO DA IMPLEMENTAÇÃO DE CONFIGURAÇÃO DO SLIDE
  slide:Slide={url:""}
  listaSlides:Slide[]
  photoSlideName:String
  title=new FormControl('',[Validators.maxLength(40),Validators.minLength(3)])
  caption=new FormControl('',[Validators.maxLength(300),Validators.minLength(5)])

  cadastraSlide():void{
    if(this.slide.url!=""){
      this.slide.caption=this.caption.value
      this.slide.title=this.title.value
      this.slideService.novaImagem(this.slide).subscribe(slide=>{
        this.router.navigate(['/home'])
      })
    }else{
      alert('Por Favor selecione o arquivo')
    }
  }

  pegaSlide():void{
    this.slideService.pegaImagens().subscribe(slides=>{
      this.listaSlides=slides
    })
  }

  excluirSlide(_id:string):void{
    if(confirm("Deseja realmente excluir esta imagem do slide principal?")){
      this.slideService.excluiImagem(_id).subscribe(slide=>{
        this.router.navigate(['/home'])
      })
    }  
  }

  arquivoSlideSelecionado(event) {
    let arquivo:File =event.target.files[0]
    var myReader:FileReader = new FileReader()
    myReader.onloadend = (e) => {
      this.slide.url = myReader.result.toString()
      this.photoSlideName=arquivo.name
    }
    myReader.readAsDataURL(arquivo);
  }


  //AQUI TERMINA IMPLEMENTAÇÃO DOS PRODUTOS PROMOCIONAIS E COMEÇA IMPLEMENTAÇÃO DO SLIDE MERCHANDISING
  listaMerchan:Merchan[]
  merchan:Merchan={url:"",link:""}
  photoMerchanName:String
  url=  new FormControl('',[Validators.required])
  link = new FormControl('',[Validators.required])

  cadastraMerchan():void{
    if(this.link.valid && this.photoMerchanName!=''){
      this.merchan.link = this.link.value
      this.merchanService.cadastraMerchan(this.merchan).subscribe(merchan=>{
        this.pegaMerchan()
      })
    }
  }

  pegaMerchan():void{
    this.merchanService.pegaMerchan().subscribe(merchans=>{
      this.listaMerchan=merchans
    })
  }

  excluiMerchan(_id:string):void{
    this.merchanService.excluiMerchan(_id).subscribe(merchan=>{
      this.pegaMerchan()
    })
  }

  arquivoMerchanSelecionado(event) {
    let arquivo:File =event.target.files[0]
    var myReader:FileReader = new FileReader()
    myReader.onloadend = (e) => {
      this.merchan.url = myReader.result.toString()
      this.photoMerchanName=arquivo.name
    }
    myReader.readAsDataURL(arquivo);
  }

}
