import { Component, OnInit, Sanitizer } from '@angular/core';
import { NoticeService } from 'src/app/services/notice.service';
import { Notice } from 'src/app/admin/notice/notice.model';
import { ActivatedRoute, Params } from '@angular/router';
import { Image, Description, DescriptionStrategy, GalleryService, ImageModalEvent } from '@ks89/angular-modal-gallery';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cards-products',
  templateUrl: './cards-products.component.html',
  styleUrls: ['./cards-products.component.css']
})
export class CardsProductsComponent implements OnInit {
  displayModal:boolean=false
  public loading = true;
  public type:string='';
  listaProdutos:Notice[]=[]
  constructor(private noticeService:NoticeService, private route:ActivatedRoute, private sanitizer:DomSanitizer,private galleryService: GalleryService) { }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.listaProdutos=[];
      this.pegaProdutos()
      })
    
  }

  pegaProdutos():void{
    this.type=this.route.snapshot.paramMap.get('type');
    if (this.noticeService.listNotice==undefined){

      this.noticeService.pegaNoticia().subscribe((notices:Notice[])=>{
        for(let notice of notices){
          if (notice['tag']==this.type){
            this.listaProdutos.push(notice);
          }
        }
        this.noticeService.listNotice=notices;
        this.loading=false;
      });
    }else{
     
      for(let notice of this.noticeService.listNotice){
    
        if (notice['tag']==this.type){
          this.listaProdutos.push(notice);
        }
      }
      
      this.loading=false;
    }

    

      }
/*
      let cont=0

      this.images=[]


      
      for(let produto in this.noticeService.listNotice){
       
        let base64Image: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.noticeService.listNotice[cont].photo.toString());
        this.images.push(new Image(
          cont,
          {
            img:base64Image
          }
        ))
        cont+=1
        
      }
      */

  }
  /*

  customDescriptionHideIfEmpty: Description = {
    strategy: DescriptionStrategy.HIDE_IF_EMPTY,
    imageText: 'Look this image ',
    numberSeparator: ' of ',
    beforeTextDescription: ' => '
  };

images:Image[]


openModalViaService(id: number | undefined, index: number) {
  console.log('opening gallery with index ' + index);
  this.galleryService.openGallery(id, index);
  this.displayModal=!this.displayModal
}

onCloseImageModal(event: ImageModalEvent) {
  this.displayModal=!this.displayModal
}

  
}
*/