import { Injectable } from "@angular/core";
import { Slide } from "../home/slide-destaque/model/slide.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {apiUrl} from './api'
import { LoginService } from "./login.service";

@Injectable()
export class SlideService{
    
    constructor(private http:HttpClient, private loginService:LoginService){}

    listSlides:Slide[];

    novaImagem(slide:Slide):Observable<any>{
    const headers:HttpHeaders = new HttpHeaders().set('Content-Type','application/json').set('Authorization',`Bearer ${this.loginService.user.acessToken}`).set('Access-Control-Request-Headers','*');

        return this.http.post(`${apiUrl}/slide`,JSON.stringify({
            url:slide.url,
            caption:slide.caption,
            title:slide.title
        }),{headers:headers})
    }

    pegaImagens():Observable<any>{
        return this.http.get(`${apiUrl}/slide`);
    }

    excluiImagem(_id:string):Observable<any>{
        const headers:HttpHeaders = new HttpHeaders().set('Content-Type','application/json').set('Authorization',`Bearer ${this.loginService.user.acessToken}`).set('Access-Control-Request-Headers','*');
        return this.http.delete(`${apiUrl}/slide/${_id}`,{headers:headers})
    }
}