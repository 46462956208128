import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'angular2-carousel';

import {trigger, state, style,transition,animate, keyframes} from '@angular/animations'

@Component({
  selector: 'app-cards-tags',
  templateUrl: './cards-tags.component.html',
  styleUrls: ['./cards-tags.component.css'],
  animations:[
    trigger('cardsTransition',[
      state('ready',style({opacity:1})),
      transition('void=>ready',[
        style({opacity:0,transform:'translate(-30px,-10px)'}),
        animate('500ms 0s ease-in-out')
      ])
    ])
  ]
})
export class CardsTagsComponent implements OnInit {

  cardsTransition='ready'
  constructor() { }

  ngOnInit() {
  }


}
