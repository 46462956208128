import { Component, OnInit } from '@angular/core';
import { Merchan } from 'src/app/admin/merchan/merchan.model';
import { MerchanService } from 'src/app/services/merchan.service';
import { IImage } from 'ng-simple-slideshow';
import {trigger, state, style,transition,animate, keyframes} from '@angular/animations'


@Component({
  selector: 'app-merchan',
  templateUrl: './merchan.component.html',
  styleUrls: ['./merchan.component.css'],
  animations:[
    trigger('merchanTransition',[
      state('ready',style({opacity:1})),
      transition('void=>ready',[
        style({opacity:0,transform:'translate(-30px,-10px)'}),
        animate('1000ms 0s ease-in-out')
      ])
    ])
  ]
})
export class MerchanComponent implements OnInit {
  constructor(private merchanService:MerchanService){}
  public loading = true;
  merchanTransition='ready';
  listaMerchans:Merchan[]=[];


  imageUrls: (string | IImage)[] = [];
  ngOnInit() {
    if(this.merchanService.listMerchan==undefined){
      this.merchanService.pegaMerchan().subscribe(merchans=>{
        this.merchanService.listMerchan=merchans;
        this.listaMerchans=merchans;
        this.imageUrls=this.listaMerchans;
        this.loading=false; 
      })  
    }else{
      this.listaMerchans=this.merchanService.listMerchan;
      this.imageUrls=this.listaMerchans;
      this.loading=false; 
    }
   
  }


}
