import { Component, OnInit } from '@angular/core';
import { NoticeService } from '../services/notice.service';
import { Notice } from '../admin/notice/notice.model';
import { ActivatedRoute,  Params } from '@angular/router';

import {trigger, state, style,transition,animate, keyframes} from '@angular/animations'
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  type:string
  icon:string
  constructor(private route: ActivatedRoute) {   }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      
      this.type=this.route.snapshot.paramMap.get('type');
     
      if(this.type=='Animais'){
        this.icon='pets'
      }else if(this.type=='Ferramentas'){
        this.icon='build'
      }else if(this.type=="Camping"){
        this.icon='landscape'
      }else{
        this.type=this.type.replace("_"," ").replace("_"," ")
        this.icon="directions_bike"
      }
   })
   
  }



}
