import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CarouselComponent } from 'angular2-carousel';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],

  encapsulation: ViewEncapsulation.Emulated
})
export class FooterComponent implements OnInit {

  cartoesAceitos=[
    '../../assets/cartoes/alelo.png',
    '../../assets/cartoes/americna.png',
    '../../assets/cartoes/aspn.png',
    '../../assets/cartoes/aura.png',
    '../../assets/cartoes/bancoagiplam.png',
    '../../assets/cartoes/biq.png',
    '../../assets/cartoes/bonuscpa.png',
    '../../assets/cartoes/cabal.png',
    '../../assets/cartoes/coopercard.png',
    '../../assets/cartoes/dinersclub.png',
    '../../assets/cartoes/elo.png',
    '../../assets/cartoes/giftpass.png',
    '../../assets/cartoes/goodcard.png',
    '../../assets/cartoes/greencard.png',
    '../../assets/cartoes/hiper.png',
    '../../assets/cartoes/hipercard.png',
    '../../assets/cartoes/maestro.png',
    '../../assets/cartoes/mastercard.png',
    '../../assets/cartoes/personalcard.png',
    '../../assets/cartoes/premiumpass.png',
    '../../assets/cartoes/redemasterfarma.png',
    '../../assets/cartoes/senff.png',
    '../../assets/cartoes/sodexo.png',
    '../../assets/cartoes/ticket.png',
    '../../assets/cartoes/triocard.png',
    '../../assets/cartoes/utilcard.png',
    '../../assets/cartoes/visa.png'
  ]

  constructor() { }

  @ViewChild('topCarousel') topCarousel: CarouselComponent;
  ngOnInit() {
    
  }

  reinit():void{
    this.topCarousel.slideTo(0);
  }

}
