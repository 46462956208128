import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { User } from '../admin/user/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private loginService:LoginService) { }

  ngOnInit() {
  }
  
  getUserName():String{
    return this.loginService.user.name
  }

  estaLogado():boolean{
    return this.loginService.estaLogado()
  }

  logout():void{
    this.loginService.logout()
  }

}
