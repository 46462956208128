import { Component, OnInit } from '@angular/core';
import { EmailService } from '../services/email.service';
import { FormControl, Validators } from '@angular/forms';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private emailService:EmailService,private router:Router) { }

  formValido:boolean=false
  name = new FormControl('',[Validators.required,Validators.maxLength(20)])
  email=new FormControl('',[Validators.email])
  message=new FormControl('',[Validators.required,Validators.maxLength(250)])
  tel=new FormControl(''  )

  ngOnInit() {
  }


  verificaForm():void{
    if(this.email.valid && this.name.valid && this.message.valid && this.tel.valid){
      this.formValido=true
    }else{
      this.formValido=false
    }
  }

  enviaEmail():void{
    alert('Enviando e-mail, Aguarde...')
    this.emailService.enviaEmail(this.name.value,this.email.value,this.message.value,this.tel.value).subscribe(email=>{
      alert('Sua mensagem foi enviada com sucesso. Obrigado!')
      this.router.navigate(['/home'])
    })
  }

}
