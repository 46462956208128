import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddNoticeRoutingComponent } from "./add-noticeRouting.component";
import { AddNoticeComponent } from "../add-notice.component";

export const AddNoticeRoutes:Routes=[
    {
        path:'add-notice',
        component:AddNoticeRoutingComponent,
        children:[
            {
                path:'',
                component:AddNoticeComponent
            }
        ]
    }
]

@NgModule({
    imports:[
        RouterModule.forChild(AddNoticeRoutes)
    ],
    exports:[
        RouterModule
    ]
})
export class AddNoticeRoutingModule{}