import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name:'realBr'
})
export class RealPipeTransform implements PipeTransform{
    transform(valor: number): string {
        if (!valor) {
           return 'R$ 0,00';
        }
        let newValor= valor.toFixed(2).toString();
        newValor = newValor.toString().replace('.',',');
       
       return 'R$ ' + newValor;
   }

}