import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { HomeRoutingComponent } from "./home-routing.component";
import { HomeComponent } from "../home.component";

export const HomeRoutes:Routes=[
    {
        path:'home',
        component:HomeRoutingComponent,
        children:[
            {
                path:'',
                component:HomeComponent
            }
        ]
    }
];

@NgModule({
    imports:[
        RouterModule.forChild(HomeRoutes)
    ],
    exports:[
        RouterModule
    ]
})
export class HomeRoutingModule{}