import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { ContactRoutingComponent } from "./contact-routing.component";
import { ContactComponent } from "../contact.component";

export const ContactRoutes:Routes=[
    {
        path:'contact',
        component:ContactRoutingComponent,
        children:[
            {
                path:'',
                component:ContactComponent
            }
        ]
    }
];

@NgModule({
    imports:[
        RouterModule.forChild(ContactRoutes)
    ],
    exports:[
        RouterModule
    ]
})
export class ContactRoutingModules{}