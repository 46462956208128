import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {apiUrl} from './api'

@Injectable()
export class EmailService{
    constructor(private http:HttpClient){}

    enviaEmail(name:string,email:string,message:string,tel:string):Observable<any>{
        const headers = new HttpHeaders().set('Content-Type','application/json').set('Access-Control-Request-Headers','*');
        return this.http.post(`${apiUrl}/email`,JSON.stringify({
            name:name,
            tel:tel,
            message:message,
            email:email
        }),{headers:headers})
    }
}