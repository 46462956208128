import { NgModule } from "@angular/core";
import { MaterialModule } from "src/app/shared/modules/material.module";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { ProductsComponent } from "../products.component";
import { ProductsRoutingComponent } from "../routing/products-routing.component";
import { FormsModule } from "@angular/forms";
import { ProductsRoutingModule } from "../routing/products-routing.module";
import { CardsProductsComponent } from "../cards-products/cards-products.component";
import { PipesModule } from "src/app/shared/modules/pipes.module";
import { ModalGalleryModule } from "@ks89/angular-modal-gallery";
import { FooterComponent } from "src/app/footer/footer.component";
import { NgxLoadingModule } from "ngx-loading";

@NgModule({
    imports:[
        MaterialModule,
 
        BrowserModule,
        FormsModule,
        ProductsRoutingModule,
        PipesModule,
        ModalGalleryModule,
        
    NgxLoadingModule.forRoot({}),
    CommonModule
    ],
    declarations:[
        ProductsRoutingComponent,
        ProductsComponent,
        CardsProductsComponent
    ],
    exports:[
        ProductsComponent
    ],
    providers:[
    ]

})
export class ProductsModule{}