import { NgModule } from "@angular/core";
import { ContactRoutingModules } from "../routing/contact-routing.module";
import { MaterialModule } from "src/app/shared/modules/material.module";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { ContactComponent } from "../contact.component";
import { ContactRoutingComponent } from "../routing/contact-routing.component";
import { EmailService } from "src/app/services/email.service";
import { ComponentsSharedModule } from "src/app/shared/modules/componentsShared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports:[
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ContactRoutingModules,
        MaterialModule,
        CommonModule,
        BrowserModule,
        ComponentsSharedModule
        
    ],
    declarations:[
        ContactComponent,
        ContactRoutingComponent
    ],
    exports:[
        ContactComponent
    ],
    providers:[
        EmailService
    ]
})
export class ContactModule{}