import { Component, OnInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow'
import { SlideService } from 'src/app/services/slide.service';
import { Slide } from './model/slide.model';
@Component({
  selector: 'app-slide-destaque',
  templateUrl: './slide-destaque.component.html',
  styleUrls: ['./slide-destaque.component.css']
})
export class SlideDestaqueComponent implements OnInit {
  public loading = true;
  constructor(private slideService: SlideService) { }

  listaSlide: Slide[]=[];


  imageUrls: (string | IImage)[] = [];
  ngOnInit() {
    this.listaSlide.push(new Slide('','','',''));
    if(this.slideService.listSlides==undefined){
      this.slideService.pegaImagens().subscribe(slides => {
        this.slideService.listSlides=slides;
        this.listaSlide = slides;
        this.imageUrls = this.listaSlide;
        this.loading=false;
      })
    }else{
      this.listaSlide=this.slideService.listSlides;
      this.imageUrls = this.listaSlide;
    }

  }
}
