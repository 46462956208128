import { Injectable } from "@angular/core";
import { LoginService } from "./login.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Merchan } from "../admin/merchan/merchan.model";
import { Observable } from "rxjs";
import {apiUrl} from './api'

@Injectable()
export class MerchanService{
    constructor(private loginService:LoginService,private http:HttpClient){}

    listMerchan:Merchan[];

    cadastraMerchan(merchan:Merchan):Observable<any>{
        const headers = new HttpHeaders().set('Authorization',`Bearer ${this.loginService.user.acessToken}`).set('Content-Type','application/json').set('Access-Control-Request-Headers','*');
        return this.http.post(`${apiUrl}/merchan`,JSON.stringify({
            url:merchan.url,
            link:merchan.link
        }),{headers:headers})
    }

    pegaMerchan():Observable<any>{
        return this.http.get(`${apiUrl}/merchan`)
    }

    excluiMerchan(_id:string):Observable<any>{
        const headers = new HttpHeaders().set('Authorization',`Bearer ${this.loginService.user.acessToken}`).set('Content-Type','application/json').set('Access-Control-Request-Headers','*');
        return this.http.delete(`${apiUrl}/merchan/${_id}`,{headers:headers})
    }
}