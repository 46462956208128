import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { LoginRoutingModule } from "../routing/login-routing.module";
import { LoginComponent } from "../login.component";
import { LoginRoutingComponent } from "../routing/login-routing.component";
import { MaterialModule } from "src/app/shared/modules/material.module";
import { LoginService } from "src/app/services/login.service";

@NgModule({
    imports:[
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        MaterialModule,
        LoginRoutingModule
    ],
    declarations:[
        LoginComponent,
        LoginRoutingComponent
    ],
    exports:[
        LoginComponent
    ],
    providers:[LoginService]
})
export class LoginModule{}