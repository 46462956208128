import { SlideDestaqueComponent } from "src/app/home/slide-destaque/slide-destaque.component";
import { NgModule } from "@angular/core";
import { SlideshowModule } from "ng-simple-slideshow";
import { SlideService } from "src/app/services/slide.service";

        

        const modules=[
            SlideDestaqueComponent
        ]

        @NgModule({
            imports:[SlideshowModule],
            exports:[...modules],
            declarations:[...modules],
            providers:[SlideService]
        })
        export class ComponentsSharedModule{}